import { Button, InputFieldFormik } from "Components";
import { resetPassword } from "Redux/Actions/Auth";
import { STATUS_CODES } from "Services/Api/Constants";
import { IMAGES } from "Shared/Images";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";


const NewPassword = ({setOpen = ()=>{} }) => {
  const {t} = useTranslation();
  const validate = Yup.object({
    newPassword: Yup.string()
      .required(t("required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        t("password-val")
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], t("password-match"))
      .required(t("required")),
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const handleSubmit = (value) => {

    let payload = { ...value };
    delete payload.confirmPassword;
    delete payload.oldPassword;
    dispatch(
      resetPassword(payload, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if(response.statusCode === STATUS_CODES.SUCCESS){
            setOpen(false);
        }
      })
    );
  };
  return (
        <div className="form_card bg-white text-start p-4">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validate}
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
          >
            {(props) => (
              <>
                <Form
                  className="form_pad"
                >
                  <div className="row g-3 ">
                    <div className="col-12">

                          <div className="form-group password-view">
                            <InputFieldFormik
                              name={"newPassword"}
                              label={t("new-password")}
                              type={showPassword.newPassword ? "text" : "password"}
                              required={true}
                            />
                            <span
                              className="pwd-view-img"
                              onClick={(prev) =>
                                setShowPassword({ ...prev, newPassword: !showPassword.newPassword })
                              }
                            >
                              <img
                                src={showPassword.newPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                className="img-fluid"
                                alt="imgOpen"
                              />
                            </span>
                          </div>
                    </div>

                    <div className="col-12">

                          <div className="form-group password-view">
                            <InputFieldFormik
                              name={"confirmPassword"}
                              label={t("verify-password")}
                              type={showPassword.confirmPassword ? "text" : "password"}
                              required={true}
                            />
                            <span
                              className="pwd-view-img"
                              onClick={(prev) =>
                                setShowPassword({ ...prev, confirmPassword: !showPassword.confirmPassword })
                              }
                            >
                              <img
                                src={showPassword.confirmPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                className="img-fluid"
                                alt="imgOpen"
                              />
                            </span>
                          </div>

                    </div>
                    <div className="col-12 text-center justify-content-center common_btn">
                      <Button className="btn btn-lg btn-primary" type="submit">
                      {t("save")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
  );
};

export default NewPassword;
