import jackpot from "../Assets/Images/JACKPOT.png";
import group from "../Assets/Images/DigitalPayment/Group.png";
import lic from "../Assets/Images/DigitalPayment/lic.png";
import master from "../Assets/Images/DigitalPayment/master.png";
import play from "../Assets/Images/DigitalPayment/play.png";
import visa from "../Assets/Images/DigitalPayment/visa.png";

import btc from "../Assets/Images/e-money/btc.png";
import crypto from "../Assets/Images/e-money/Crypto.png";
import eth from "../Assets/Images/e-money/eth.png";
import telkomsel from "../Assets/Images/Bank/telkomsel.png";
import xx from "../Assets/Images/Bank/xx.png";

import bank_bri from "../Assets/Images/Bank/bank_bri.png";
import bca from "../Assets/Images/Bank/bca.png";
import bni from "../Assets/Images/Bank/bni.png";
import mandiri from "../Assets/Images/Bank/mandiri.png";
import Pulsa from "../Assets/Images/Bank/Pulsa.png";
import Bmm from "../Assets/Images/license/bmm.png";
import gamblingco from "../Assets/Images/license/gambling-co.png";
import gaming from "../Assets/Images/license/gaming.png";
import itech from "../Assets/Images/license/itech.png";
import mga from "../Assets/Images/license/mga.png";
import pagor from "../Assets/Images/license/pagor.png";
import begambler from "../Assets/Images/play/be-gambler.png";
import eighteen from "../Assets/Images/play/eighteen.png";
import gt from "../Assets/Images/play/gt.png";
import gtt from "../Assets/Images/play/gtt.png";
import dana from "../Assets/Images/e-money/Crypto.png";
import ovo from "../Assets/Images/e-money/ovo.png";
import gopay from "../Assets/Images/e-money/gopay.png";
import whatsapp from "../Assets/Images/icons/whatsapp.png";
import email from "../Assets/Images/icons/email.png";
import phone from "../Assets/Images/icons/phone.png";
import skype from "../Assets/Images/icons/skype.png";
import wechat from "../Assets/Images/icons/wechat.png";
import telegram from "../Assets/Images/icons/telegram.png";
import insta from "../Assets/Images/icons/insta.png";
import facebook from "../Assets/Images/icons/facebook.png";
import Logo from "../Assets/Images/logo.png";
import plus from "../Assets/Images/icons/plus_icon.svg";
import arrowbtm from "../Assets/Images/icons/down_arrow.svg";
import cards from "../Assets/Images/cards-3.png";
import yellowCard from "../Assets/Images/yellow-cards.png";
import sliderRing from "../Assets/Images/slider-ring.png";
import eyeOpen from "../Assets/Images/icons/show_password.svg";
import eyeClose from "../Assets/Images/icons/hide_password.svg";
import copy from "../Assets/Images/icons/copy_icon.svg";

import empty from "../Assets/Images/icons/empty.png";
import dbank from "../Assets/Images/deposit-bank.png";
import usflg from "../Assets/Images/icons/us-flag.png"
import calendar from "../Assets/Images/icons/calender_icon.svg";

import elk from "../Assets/Images/game-provider/elk.png";
import evolution from "../Assets/Images/game-provider/evolution.png";
import isoftbet from "../Assets/Images/game-provider/isoftbet.png";
import microgaming from "../Assets/Images/game-provider/microgaming.png";
import netent from "../Assets/Images/game-provider/netent.png";
import playgo from "../Assets/Images/game-provider/playgo.png";
import pramatic from "../Assets/Images/game-provider/pramatic.png";
import quickspam from "../Assets/Images/game-provider/quickspam.png";
import redtiger from "../Assets/Images/game-provider/red-tiger.png";
import yggdrasil from "../Assets/Images/game-provider/yggdrasil.png";
import indonesiaflag from "../Assets/Images/indonesia.png";
import arrows_rotate from "../Assets/Images/icons/arrows_rotate.svg";
import JackpotPic from "../Assets/Images/jackport.png";

export const IMAGES = {
   eyeOpen:eyeOpen,
   eyeClose:eyeClose,
   cards:cards,
   yellowCard:yellowCard,
   sliderRing:sliderRing,
   jackpot : jackpot,
   group : group,
   lic : lic,
   master : master,
   play : play,
   visa : visa,
   btc : btc,
   eth : eth,
   telkomsel: telkomsel,
   xx : xx,
   crypto : crypto,
   bank_bri : bank_bri,
   bca : bca,
   bni : bni,
   mandiri : mandiri,
   Pulsa : Pulsa,
   usflg : usflg,
   Bmm: Bmm,
   gamblingco: gamblingco,
   gaming: gaming,
   itech: itech,
   mga: mga,
   pagor: pagor,
   begambler: begambler,
   eighteen: eighteen,
   gt: gt,
   gtt: gtt,
   dana: dana,
   ovo: ovo,
   gopay: gopay ,
   email: email,
   phone: phone,
   wechat: wechat,
   whatsapp: whatsapp,
   skype:skype,
   telegram: telegram,
   logo:Logo,
   insta:insta,
   facebook:facebook,
   plus:plus,
   arrowbtm:arrowbtm,
   copy : copy,
   empty : empty,
   calendar : calendar,
   dbank : dbank,
   elk : elk,
   evolution : evolution,
   isoftbet : isoftbet,
   microgaming : microgaming,
   netent : netent,
   playgo : playgo,
   pramatic : pramatic,
   quickspam : quickspam,
   redtiger : redtiger,
   yggdrasil : yggdrasil,
   indonesiaflag : indonesiaflag,
   arrows_rotate : arrows_rotate,
   JackpotPic: JackpotPic
}