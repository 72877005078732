import React from "react";
import moment from "moment";
import { Button } from "Components";
import { useSelector } from "react-redux";
import './style.scss';
import casinoImg from "../../Assets/Images/casino.png";

const PromotionDetail = () => {
  const details = useSelector((state) => state?.common?.promotionDetail);
  return (
    <div className="promotion_sec">
      <div className="container">
        <div className="w-100 d-flex promotion_items">
          <figure className="mb-md-0">
            <img src={casinoImg} alt="Image Title" width="200" />
          </figure>
          <div className="promotion_decription">
            <div className="row align-items-center h-100">
              <div className="col-md-8">
                {details.isFirstDeposit ? (
                  <span className="badge btn-red mb-3">First Deposit</span>
                ) : (
                  ""
                )}
                <h1 className="h1">Podcast Title</h1>
                <div className="postcard__preview-txt">{details.text}</div>
                {details.isFirstDeposit ? (
                  <span>Min Deposit <span>$20</span></span>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 text-start text-md-center py-3">
                <Button className="btn btn-md btn-primary">
                  See Details
                </Button>
                <small className="text-mute mt-2 d-block">Terms & Conditions</small>
              </div>
            </div>
          </div>
        </div>
        <>
          <div className="d-flex align-items-center flex-wrap justify-content-between">
            <h1 className="h3 mb-3">Share Draw</h1>
            <span className="btn btn-md btn-primary mb-3">
              Promo Period: {moment(new Date()).format("DD MMMM YYYY")} - {moment(new Date()).format("DD MMMM YYYY")}
            </span>
          </div>
          <div className="content">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
            est laborum.</p>
            <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
            explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur
            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia
            dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et
            dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in
            ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
            pariatur?</p>
            <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?</p>
          </div>
        </>
      </div>
    </div>
  );
};

export default PromotionDetail;
