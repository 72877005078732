import React from 'react'
import Button from '../Button'
import CustomModal from '../CustomModal'
import { useTranslation } from 'react-i18next';
import './style.scss';

const WelcomeModal = ({ open, setOpen = () => { } }) => {
  const { t } = useTranslation();

  return (
    <CustomModal isOpen={open} big={true} handleToggle={() => setOpen(!open)} title={t("welcome-to-bt2")}>
      <div className="welcome_content text-black">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
          ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
          nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.</p>
        <div className="py-3">
          <h5 className='h6'>Lorem ipsum dolor sit</h5>
          <ul className='ps-3'> {t("okay")}
            <li>Lorem ipsum</li>
            <li>Lorem ipsum</li>
            <li>Lorem ipsum</li>
            <li>Lorem ipsum</li>
            <li>Lorem ipsum</li>
          </ul>
        </div>
        <div className="py-3">
          <h5 className='h5'>Quis nostrud</h5>
          <div className="mt-3">
            <video
              src="https://media.istockphoto.com/id/1202294957/video/dealer-woman-shuffles-the-poker-cards-and-performing-trick-with-cards-shot-on-red-epic-cinema.mp4?s=mp4-640x640-is&k=20&c=PPCkxZ8VIQJ02jfH7Zzcfwd_kkrqaoPAXcVt6cqQenY="
              alt=""
              className='img-fluid'
              controls
              controlsList="nodownload nofullscreen noremoteplayback noplaybackspeed"
            />
          </div>
        </div>
        <div className="mt-3 text-center text-md-end">
          <Button className="btn btn-lg btn-primary" onClick={() => setOpen(false)}>
            {t("okay")}
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default WelcomeModal