import { Button } from "Components";
import React, { useEffect, useState } from "react";
import DepositForm from "./Components/DepositForm";
import AccountInfo from "./Components/AccountInfo";
import LastTransactions from "./Components/LastTransactions";
import WithDraw from "./Components/WithDraw";
import { useDispatch, useSelector } from "react-redux";
import { getAccountTypes, getBankAccounts } from "Redux/Actions/user";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { STATUS_CODES } from "Services/Api/Constants";
import { useLocation } from "react-router-dom";
import { LABELS, NAVIGATION_BAR } from "Shared/Constants";

const Deposit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  location.type = location.type ? location.type : NAVIGATION_BAR.DEPOSIT;
  const [active, setActive] = useState(location.type);

  const showActive = (value) => {
    switch (value) {
      case 1:
        return <DepositForm setActive={setActive} />;
      case 2:
        return <WithDraw setActive={setActive} />;
      case 3:
        return <LastTransactions />;
      case 4:
        return <AccountInfo />;
      default:
        return <DepositForm setActive={setActive} />;
    }
  };

  useEffect(() => {
    setActive(location.type);
  }, [location]);

  return (
    <div className="py-4 py-md-5 depost_withdrawal_sec">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center tabs_list">
          <Button className={`btn btn-secondary ${active == 1 ? "current" : ""}`} onClick={() => setActive(1)}>
            {t("deposit")}
          </Button>
          <Button className={`btn btn-secondary ${active == 2 ? "current" : ""}`} onClick={() => setActive(2)}>
            {t("withdraw")}
          </Button>
          <Button className={`btn btn-secondary ${active == 3 ? "current" : ""}`} onClick={() => setActive(3)}>
            {t("last-transactions")}
          </Button>
          <Button className={`btn btn-secondary ${active == 4 ? "current" : ""}`} onClick={() => setActive(4)}>
            {t("account-info")}
          </Button>
        </div>
        <div className="tab_content">{showActive(active)}</div>
      </div>
    </div>
  );
};

export default Deposit;
