import { SET_FOOTER_CMS_CONTENT, SET_PROMOTION_DETAIL, SET_PROVIDERS } from "Redux/Actions/common";

const initialState = {
  activePage : 1,
  promotionDetail : {},
  dynamicContent : {},
  providers : {},
};
const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMOTION_DETAIL : {
      return {
        ...state,
        promotionDetail : action.payload
      };
    }
    case SET_FOOTER_CMS_CONTENT : {
      return {
        ...state,
        dynamicContent : action.payload
      };
    }
    case SET_PROVIDERS : {
      return {
        ...state,
        providers : action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default CommonReducer;
