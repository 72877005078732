export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const SET_PROVIDERS = "SET_PROVIDERS";
export const GET_GAMES = "GET_GAMES";
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const SET_PROMOTION_DETAIL = "SET_PROMOTION_DETAIL";
export const GET_SOCIAL_LINKS = "GET_SOCIAL_LINKS";
export const GET_FOOTER_BANKS = "GET_FOOTER_BANKS";
export const GET_FOOTER_CMS_CONTENT = "GET_FOOTER_CMS_CONTENT";
export const SET_FOOTER_CMS_CONTENT = "SET_FOOTER_CMS_CONTENT";
export const GET_MAIN_BANNERS = "GET_MAIN_BANNERS";


export const getCategories = (callback) => {
   return {
    type : GET_CATEGORIES,
    callback
   }
}

export const getProviders = (callback) => {
   return {
    type : GET_PROVIDERS,
    callback
   }
}

export const setProviders = (payload) => {
   return {
    type : SET_PROVIDERS,
    payload
   }
}

export const getGames = (payload,callback) => {
   return {
    type : GET_GAMES,
    payload,
    callback
   }
}

export const getPromotions = (payload,callback) => {
   return {
    type : GET_PROMOTIONS,
    payload,
    callback
   }
}

export const getMainBanners = (payload,callback) => {
   return {
    type : GET_MAIN_BANNERS,
    payload,
    callback
   }
}

export const savePromotionDetail = (payload) => {
   return {
    type : SET_PROMOTION_DETAIL,
    payload
   }
}

export const getSocialLinks = (callback) => {
   return {
    type : GET_SOCIAL_LINKS,
    callback
   }
}

export const getFooterBanks= (callback) => {
   return {
    type : GET_FOOTER_BANKS,
    callback
   }
}

export const getFooterCMSContent = (callback) => {
   return {
    type : GET_FOOTER_CMS_CONTENT,
    callback
   }
}

export const setFooterCMSContent = (payload) => {
   return {
    type : SET_FOOTER_CMS_CONTENT,
    payload
   }
}