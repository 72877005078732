import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import "./style.scss";

const ThankYou = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [count, setCount] = useState(5);
  const intervalId = useRef(null);

  const clear = (id) => {
    clearInterval(id);
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setCount((prev) => --prev);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (count == 0) {
      clear(intervalId.current);
      history.push(ROUTES.HOME);
    }
  }, [count]);

  return (
    <div className="container py-md-5 py-3">
      <div className="common_screen">
        <div className="row justify-content-center mt-3">
          <div className="col-lg-8 mx-auto">
            <div className="text-center">
              <svg width="100" height="100" viewBox="0 0 598 598" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M298.667 38.0003C154.333 38.0003 37.3333 155.006 37.3333 299.334C37.3333 443.662 154.339 560.667 298.667 560.667C442.995 560.667 560 443.662 560 299.334C560 155.006 442.995 38.0003 298.667 38.0003ZM0 299.334C0 134.387 133.72 0.666992 298.667 0.666992C463.613 0.666992 597.333 134.387 597.333 299.334C597.333 464.28 463.613 598 298.667 598C133.72 598 0 464.28 0 299.334Z" fill="#169523" />
                <path fillRule="evenodd" clipRule="evenodd" d="M161.027 286.133C168.319 278.842 180.136 278.842 187.428 286.133L273.226 371.931L417.799 227.357C425.085 220.066 436.908 220.066 444.195 227.357C451.487 234.644 451.487 246.461 444.195 253.753L286.435 411.513C279.143 418.805 267.326 418.805 260.034 411.513L161.039 312.519C153.753 305.232 153.753 293.409 161.039 286.123L161.027 286.133Z" fill="#169523" />
              </svg>
              <h3 className="h3 my-4">{t("thank1")}</h3>
              <p>{t("thank2") + count + "s"}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
