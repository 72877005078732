import AboutUs from "Views/AboutUs";
import Home from "Views/Home";
import Categories from "Views/Home/Components/Categories";
import Promotion from "Views/Promotion";
import PromotionDetail from "Views/PromotionDetail";
import ResponsibleGaming from "Views/ResponsibleGaming";
import { ROUTES } from "./constants";
import HelpCenter from "Views/HelpCenter";
import TermsAndConditions from "Views/TermsAndConditions";
import HowItWorks from "Views/HowItWorks";

export const PUBLIC_ROUTES = [
  {
    path: ROUTES.HOME,
    component: Home,
    title: "Home",
  },
  {
    path: ROUTES.PROMOTION,
    component: Promotion ,
    title: "Promotions",
  },
  {
    path: ROUTES.PROMOTION_DETAILS,
    component: PromotionDetail ,
    title: "Promotion Detail",
  },
  {
    path: ROUTES.GAMES,
    component: Categories ,
    title: "Category Games",
  },
  {
    path: ROUTES.ABOUTUS,
    component: AboutUs,
    title: "About Us",
  },
  {
    path: ROUTES.RESPONSIBLE_GAMING,
    component: ResponsibleGaming,
    title: "Responsible Gaming",
  },
  {
    path: ROUTES.HELPCENTER,
    component: HelpCenter,
    title: "Help Center",
  },
  {
    path: ROUTES.TERMS_CONDITIONS,
    component: TermsAndConditions,
    title: "Terms And Conditions",
  },
  {
    path: ROUTES.HOWITWORKS,
    component: HowItWorks,
    title: "How It Works",
  },
];
