import { combineReducers } from "redux";

import authReducer from "./Auth";
import errorReducer from "./Api/ErrorReducer";
import { LOGOUT } from "../Actions/Auth";
import { ACTION_STATES } from "../Actions/ActionStates";
import LoaderReducer from "./loader";
import CommonReducer from "./common";
import GameReducer from "./game";
import UserReducer from "./user";

const appReducer = combineReducers({
  auth: authReducer,
  loading: LoaderReducer,
  error: errorReducer,
  common : CommonReducer,
  game : GameReducer,
  user : UserReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT + ACTION_STATES.SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
