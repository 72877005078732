import React, { useEffect, useState } from "react";
import DataContainer from "./Components/DataContainer";
import { Headings } from "Components/Atoms";
import { IMAGES } from "Shared/Images";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getFooterBanks, getSocialLinks } from "Redux/Actions/common";
import { CONTACT_TYPE } from "Shared/Constants";

const DIGITALPAYMENT = [IMAGES.visa, IMAGES.master];
const EMONEY = [IMAGES.dana, IMAGES.gopay, IMAGES.ovo];
const BANK = [IMAGES.bca, IMAGES.mandiri, IMAGES.bni, IMAGES.bank_bri];
const PULSA = [IMAGES.telkomsel, IMAGES.xx];
const PLAY = [IMAGES.eighteen, IMAGES.gt, IMAGES.gtt, IMAGES.begambler];
const CRYPTO = [IMAGES.btc, IMAGES.eth];
const LICENSE = [IMAGES.pagor, IMAGES.gamblingco, IMAGES.itech, IMAGES.gaming, IMAGES.Bmm, IMAGES.mga];

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [links, setLinks] = useState({});
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    dispatch(
      getSocialLinks((response) => {
        let data = response.contacts;
        let link = data.reduce((prev, curr) => {
          let res = { ...prev };
          switch (curr.contactType) {
            case CONTACT_TYPE.FACEBOOK:
              res.facebook = curr.contactDetails;
              break;
            case CONTACT_TYPE.INSTAGRAM:
              res.instagram = curr.contactDetails;
              break;
            case CONTACT_TYPE.PHONE:
              res.phone = curr.contactDetails;
              break;
            case CONTACT_TYPE.MESSAGE:
              res.message = curr.contactDetails;
              break;
            case CONTACT_TYPE.LINE:
              res.line = curr.contactDetails;
              break;
            case CONTACT_TYPE.WHATSAPP:
              res.whatsapp = curr.contactDetails;
              break;
            case CONTACT_TYPE.WE_CHAT:
              res.wechat = curr.contactDetails;
              break;
            case CONTACT_TYPE.TELEGRAM:
              res.telegram = curr.contactDetails;
              break;
            case CONTACT_TYPE.TWITTER:
              res.TWITTER = curr.contactDetails;
              break;
            case CONTACT_TYPE.TIKTOK:
              res.tiktok = curr.contactDetails;
              break;
            case CONTACT_TYPE.EMAIL:
              res.email = curr.contactDetails;
              break;

            default:
          }
          return res;
        }, {});
        setLinks(link);
      })
    );

    dispatch(
      getFooterBanks((response) => {
        setBanks(response);
      })
    );
  }, []);

  return (
    <footer className="pt-4 text-muted footer">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center footer_pages_links pb-md-4 py-3">
          <ul className="list-unstyled p-0 m-0 d-flex justify-content-center flex-wrap align-items-center ">
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.ABOUTUS)}>{t("about-us")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.RESPONSIBLE_GAMING)}>{t("responsible-gaming")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.HELPCENTER)}>{t("help-center")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.TERMS_CONDITIONS)}>{t("terms-conditions")}</a>
            </li>
          </ul>
        </div>
        <div className="justify-content-start flex-wrap align-items-center mt-md-4 mt-3  partners_secn">
          {banks?.length
            ? banks.map((item, index) => (
                <div
                  key={index}
                  className="bank common_partner d-flex align-items-md-center align-items-start flex-wrap"
                >
                  <div className="me-3 partner_name">
                    <span>{item._id} </span>
                  </div>
                  <div className="partner_logo">
                    {item?.isActaccountBrand?.length
                      ? item?.isActaccountBrand.map((itm, indx) => (
                          <a key={indx} className="link-secondary">
                            <img className="img-fluid" src={itm?.brandImage} alt="Bank" width="100px" />
                          </a>
                        ))
                      : ""}
                  </div>
                </div>
              ))
            : ""}
          {/* <div className="bank common_partner d-flex align-items-md-center align-items-start flex-wrap">
            <div className="me-3 partner_name">
              <span>{t("bank")} </span>
            </div>

            <div className="partner_logo">
              {BANK.map((item, index) => (
                <a key={index} className="link-secondary">
                  <img className="img-fluid" src={item} alt="Bank" />
                </a>
              ))}
            </div>
          </div>
          <div className="pulsa common_partner d-flex align-items-md-center align-items-start flex-wrap">
            <div className="me-3 partner_name">
              <span>{t("pulsa")} </span>
            </div>

            <div className="partner_logo">
              {PULSA.map((item, index) => (
                <a key={index} className="link-secondary">
                  <img src={item} className="img-fluid" alt="pulsa" />
                </a>
              ))}
            </div>
          </div>
          <div className="emoney common_partner d-flex align-items-md-center align-items-start flex-wrap">
            <div className="me-3 partner_name">
              <span>{t("e-money")} </span>
            </div>

            <div className="partner_logo">
              {EMONEY.map((item, index) => (
                <a key={index} className="link-secondary">
                  <img className="img-fluid" src={item} alt="emoney" />
                </a>
              ))}
            </div>
          </div>
          <div className="crypto common_partner d-flex align-items-md-center align-items-start flex-wrap">
            <div className="me-3 partner_name">
              <span>{t("crypto")}</span>
            </div>

            <div className="partner_logo">
              {CRYPTO.map((item, index) => (
                <a key={index}>
                  <img className="img-fluid" src={item} alt="crypto" />
                </a>
              ))}
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="digital common_partner d-flex align-items-md-center align-items-start">
              <div className="me-3 partner_name">
                <span>{t("digital-payment")}</span>
              </div>

              <div className="partner_logo">
                {DIGITALPAYMENT.map((item, index) => (
                  <a key={index} className="link-secondary">
                    <img className="img-fluid" src={item} alt="digital-payment" />
                  </a>
                ))}
              </div>
            </div>
            <div className="play common_partner d-flex align-items-md-center align-items-start flex-wrap">
              <div className="me-3 partner_name">
                <span>{t("play-resp")} </span>
              </div>

              <div className="partner_logo">
                {PLAY.map((item, index) => (
                  <a key={index} className="me-4">
                    <img src={item} alt=" " width="" />
                  </a>
                ))}
              </div>
              <div className="license_no">
                <span>LIC NO. 17-0006-RE</span>
              </div>
            </div>
          </div>
          <div className="license common_partner d-flex align-items-md-center align-items-start flex-wrap">
            <div className="me-3 partner_name">
              <span>{t("license")}</span>
            </div>

            <div className="partner_logo">
              {LICENSE.map((item, index) => (
                <a key={index} className="me-4">
                  <img className="img-fluid" src={item} alt="license" />
                </a>
              ))}
            </div>
          </div> */}
        </div>

        <div className="main_footer">
          <div className="mt-4">
            <div className="row px-0 px-lg-4">
              <div className="col-xl-5 col-lg-4 col-md-12 col-sm-6 col-12 mb-4">
                <div className="footer_item pe-xl-5">
                  <h6 className="h6 text-uppercase fw-bold mb-4">
                    <img src={IMAGES.logo} alt="Logo" className="img-fluid" width="200" />
                  </h6>
                  <p>
                    Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod
                    maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                  </p>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-7 col-sm-6 col-12 mb-4">
                <div className="footer_item">
                  <h6 className="h6 text-uppercase fw-bold mb-4">{t("contact-us")}</h6>
                  <div className="footer_list">
                    <ul>
                      {links?.whatsapp ? (
                        <li>
                          <a href={links?.whatsapp} target="_blank">
                            <em>
                              <img src={IMAGES.whatsapp} alt="Whatsapp" width="34" />
                            </em>{" "}
                            Whatsapp
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.telegram ? (
                        <li>
                          <a href={links?.telegram} target="_blank">
                            <em>
                              <img className="img-fluid" src={IMAGES.telegram} width="34" alt="Telegram" />
                            </em>{" "}
                            Telegram
                          </a>
                        </li>
                      ) : (
                        ""
                      )}

                      {links?.wechat ? (
                        <li>
                          <a href={links?.wechat} target="_blank">
                            <em>
                              <img className="img-fluid" src={IMAGES.wechat} width="34" alt="Wechat" />
                            </em>{" "}
                            WeChat
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.skype ? (
                        <li>
                          <a href={links?.skype} target="_blank">
                            <em>
                              <img className="img-fluid" src={IMAGES.skype} width="34" alt="Skype" />
                            </em>{" "}
                            Skype
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.phone ? (
                        <li>
                          <a href={`tel:${links?.phone}`}>
                            <em>
                              <img className="img-fluid" src={IMAGES.phone} alt="Phone" width="34" />
                            </em>{" "}
                            <span>{links.phone}</span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.email ? (
                        <li>
                          <a href={`mailto:${links.email}`}>
                            <em>
                              <img className="img-fluid" src={IMAGES.email} alt="Email" width="34" />
                            </em>{" "}
                            <span>{links.email}</span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6 col-12 mb-4">
                <div className="footer_item">
                  <h6 className="h6 text-uppercase fw-bold mb-4">{t("social-media")}</h6>
                  <div className="footer_list">
                    <ul className="d-flex flex-column">
                      {links?.instagram ? (
                        <li>
                          <a href={links?.instagram} target="_blank">
                            <em>
                              <img src={IMAGES.insta} alt="Instagram" width="34" />
                            </em>
                            Instagram
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.facebook ? (
                        <li>
                          <a href={links?.facebook} target="_blank">
                            <em>
                              <img src={IMAGES.facebook} alt="Facebook" width="34" />
                            </em>
                            Facebook
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center py-3 copyright_text">
          <span>© 2022 BT2 ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
