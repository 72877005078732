export const ROUTES = {
  ABOUTUS: "/about-us",
  RESPONSIBLE_GAMING: "/responsible-gaming",
  HELPCENTER: "/help-center",
  TERMS_CONDITIONS: "/terms-conditions",
  PROMOTION: "/promotion",
  PROMOTION_DETAILS: "/promotion-details",
  HOME: "/home",
  GAMES: "/games",
  REGISTER: "/register",
  VERIFYOTP: "/verify-otp",
  FORGOT_PASSWORD: "/forgot-password",
  CHANGE_PASSWORD: "/change-password",
  REFERRAL: "/referral",
  DEPOSITS: "/deposits",
  HOWITWORKS: "/howitworks",
  MAINTENANCE: "/maintenance",
  THANKYOU : "/thankyou",
};
