import { all, fork } from "redux-saga/effects";

import watchAuth from "./Auth";
import watchUser from "./user";
import watchCommon from "./Common";

function* rootSaga() {
  yield all([fork(watchAuth),fork(watchCommon),fork(watchUser)]);
}

export default rootSaga;
