import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { IMAGES } from "Shared/Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getMainBanners } from "Redux/Actions/common";

var settings = {
  dots: true,
  infinite: true,
  // speed: 400,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  touchMove: true,
  autoplay: true,
  autoplaySpeed: 2000,
  centerPadding: "0px",
  // variableWidth:true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        swipeToSlide: true,
        draggable: true,
      },
    },
  ],
};

const PromotionSlider = ({}) => {
  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);

  const fetchBanners = () => {
    dispatch(
      getMainBanners({}, (response) => {
        let banners = [];
        if(response?.mainBanners?.length == 1){
          banners = [response?.mainBanners[0],response?.mainBanners[0],response?.mainBanners[0],response?.mainBanners[0]];
        }else if(response?.mainBanners?.length == 2){
          banners = [...response?.mainBanners,...response?.mainBanners];
        }else if(response?.mainBanners?.length == 3){
          banners = [...response?.mainBanners,response?.mainBanners[0]];
        }else{
          banners = [...response?.mainBanners];
        }
        setData(banners);
        setTotalCount(response.totalCount);
      })
    );
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  return (
    <>
      <div className="MainSlider">
        <div className="container">
          <div className="slick_Row py-md-5 mb-md-0 mb-5 py-4">

            {data?.length ? (
              <Slider {...settings} className="rev_slider">
                {data?.map((item,index) => <div key={index + "slider"}>
                  <div className="slider">
                    <div className="slider_block">
                      <figure className="mb-0 slider_img ">
                        <img src={item?.popupImage} className="img-fluid" alt="card-image" />
                      </figure>
                    </div>
                  </div>
                </div>
                )}
              </Slider>
            ) : ""}

            {/* {totalCount > 10 ?
            <div className="text-center common-btn mt-5">
              <Button
                className="btn btn-lg btn-red"
                onClick={() => {
                  history.push(ROUTES.PROMOTION);
                }}
              >
                {t("see-more-promotions")}
              </Button>
            </div> : ""} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionSlider;
