export const STRINGS = {
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE: "You appear to be offline. Please check your internet connection.",
  COPY_SUCCESS: "Link Copied Succesfully",
  NO_TRANSACTIONS: "No Transactions",
  NO_DATA_FOUND: "No data found",
};

export const ERROR = {
  CHECKBOX: "Please accept the terms and conditions",
};
export const NAVIGATION_BAR = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  LAST_TRANSACTIONS: 3,
  ACCOUNT_INFO: 4,
  DEPOSIT_FORM: 5,
};
export const LABELS = {
  DEPOSIT: "Deposit",
  WITHDRAWAL: "Withdrawal",
  LAST_TRANSACTIONS: "Last Transactions",
  ACC_INFO: "Account Info",
  CHANGEPWD: "Change Password",
  LOGOUT: "Logout",
  REFERRAL_LINK: "Referral Link",
  LOGIN: "Login",
  REGISTER: "Register",
  REQUEST: "Send Request",
  VERIFY: "Verfiy ",
  ADD_ACCOUNT: "Add Account",
  EDIT_ACCOUNT: "Update Account",
  EDIT: "Edit",
  DELETE: "Delete",
  APPLY: "Apply",
  Welcome: "Welcome to BT2",
  RESET: "Reset",
  CONFIRMATION: "Confirmation",
  CANCEL: "Cancel",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ADJUSTED: "Adjusted",
  PENDING: "Pending",
  CANCELLED: "Canceled",
  OKAY: "Okay",
  FORGOT_PASSWORD: "Forgot Password?",
  USERNAME_EMAIL: "Username/Email",
  PASSWORD: "Password",
};

export const indexHistory = (index, limit) => {
  if (index == 0) {
    return index + 1;
  } else {
    return index * limit + 1;
  }
};

export const TRANSACTION_TYPE_OPTIONS = [
  { label: "Deposit", value: 1 },
  { label: "Withdrawal", value: 2 },
];

export const TRANSACTION_STATUS = {
  APPROVED: 1,
  REJECTED: 2,
  PENDING: 3,
  CANCELLED: 4
};

export const CONTACT_TYPE = {
  PHONE: 1,
  MESSAGE: 2,
  LINE: 3,
  WHATSAPP: 4,
  WE_CHAT: 5,
  TELEGRAM: 6,
  TWITTER: 7,
  FACEBOOK: 8,
  INSTAGRAM: 9,
  TIKTOK: 10,
  EMAIL : 11
};
