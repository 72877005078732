import ForgotPassword from "Views/ForgotPassword";
import Register from "Views/Register";
import VertifyOTPScreen from "Views/VerifyOtp";
import { ROUTES } from "./constants";
import ThankYou from "Views/ThankYou";

export const AUTH_ROUTES = [
  {
    path: ROUTES.REGISTER,
    component: Register,
    title: "Register",
  },
  {
    path: ROUTES.VERIFYOTP,
    component: VertifyOTPScreen,
    title: "Verify OTP",
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
    title: "Forgot Password",
  },
  {
    path: ROUTES.THANKYOU,
    component: ThankYou,
    title: "Thank You",
  },
];
