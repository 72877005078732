/* eslint-disable jsx-a11y/anchor-is-valid */
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

import { useEffect, useRef, useState } from "react";
import { Button, LoginModal, WelcomeModal } from "Components/Atoms";
import { logout } from "Redux/Actions/Auth";
import { IMAGES } from "Shared/Images";
import ForgotModal from "Components/Atoms/ForgotModal";
import CheckClickOutside from "Hooks/OutSideClick";
import { getUserDetails, setUserLang, updateUserDetails } from "Redux/Actions/user";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import { updateAuthToken } from "Shared/Axios";
import { LANGUAGE } from "Services/Api/Constants";
import { LABELS, NAVIGATION_BAR } from "Shared/Constants";

const AppLayout = ({ children }) => {
  const { i18n, t } = useTranslation();
  const loginModal = useSelector((state) => state.user.loginModal);
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.user.lang);
  const userDetails = useSelector((state) => state.user.details);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [showDD, setShowDD] = useState(false);
  const [langDD, setLangDD] = useState(false);
  const [expandNav, setExpandNav] = useState(false);
  const [active, setActive] = useState(1);
  const history = useHistory();
  const location = useLocation();

  const [openWelcome, setOpenWelcome] = useState(false);
  const prevRoute = useRef(location.pathname); 

  const [language, setLanguage] = useState("en");

  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
    setShowDD(false);
  };

  const changeRoute = (page, index) => {
    history.push(page);
    setActive(index);
    // dispatch(setActiveScreen(index))
    // hideMenu();
    setExpandNav(false);
  };

  const getActive = (value) => {
    switch (value) {
      case ROUTES.HOME:
        return 1;
      case ROUTES.PROMOTION:
        return 2;
      case ROUTES.GAMES:
        return 3;
      case ROUTES.HOWITWORKS:
        return 4;
      default:
        return "";
    }
  };

  useEffect(() => {
    setOpenLoginModal(loginModal || false);
  }, [loginModal])

  useEffect(() => {
    setActive(getActive(location?.pathname));
    if(location?.pathname == ROUTES.HOME && prevRoute.current == ROUTES.HOME){
      setOpenWelcome(true);
		}else{
      prevRoute.current = location.pathname;
    }
  }, [location]);

  useEffect(() => {
    if (token) {
      updateAuthToken(token);
      dispatch(getUserDetails());
    }
    return () => {
      setShowDD(false);
    };
  }, []);

  useEffect(() => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [lang]);

  const handleLanguageChange = (selected = LANGUAGE.ENGLISH) => {
    setLanguage(selected);
    i18n.changeLanguage(selected);
    dispatch(setUserLang(selected));
    if (token) {
      let payload = { ...userDetails };
      payload.userId = userDetails._id;
      payload.language = selected;
      delete payload._id;
      delete payload.credit;
      delete payload.userType;
      delete payload.userName;
      delete payload.status;
      dispatch(updateUserDetails(payload));
    }
    setLangDD(false);
  };

  const handleCreditRefresh = ()=> {
    dispatch(getUserDetails());
  }

  return (
    <>
      <header id="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-Nav">
          <div className="container-fluid d-flex flex-nowrap">
            <div className="left_head d-flex align-items-center">
              <a className="navbar-brand p-0" onClick={() => location.pathname == ROUTES.HOME ? "" : changeRoute(ROUTES.HOME, 1)}>
                <img className="img-fluid" src={IMAGES.logo} alt="logo" width="300" />
              </a>
              <div
                className={`collapse navbar-collapse ${expandNav ? "show" : ""}`}
                id="navbarSupportedContent"
              >
                <div className="d-lg-none px-2">
                  {token ? (
                    <div className="row justify-content-center">
                      <div className="col-6 mb-3">
                        <div className="dollar_box_item d-flex justify-content-between">
                          <span className="me-2">
                            {userDetails?.credit ? parseInt(userDetails.credit) : 0}
                          </span>
                          <button
                            className="btn"
                            type="submit"
                            onClick={handleCreditRefresh}
                          >
                            <img src={IMAGES.arrows_rotate} width="30" alt="" />
                          </button>
                        </div>
                      </div>
                      {/* <div className="col-6">
                      <CheckClickOutside onClick={() => setLangDD(false)}>
                        <div className="dropdown custom_dropdown user_Lang">
                          <button className="dropbtn" onClick={() => setLangDD(!langDD)}>
                            <img
                              src={language == "in" ? IMAGES.indonesiaflag : IMAGES.usflg}
                              width="30"
                              alt=""
                            />
                          </button>

                            <div className={`dropdown-menu dropdown-menu-end ${langDD ? "show" : ""}`}>
                              <a
                                className="dropdown-item"
                                onClick={() => handleLanguageChange(LANGUAGE.ENGLISH)}
                              >
                                <em className="me-2">
                                  <img src={IMAGES.usflg} alt="" width="30" />
                                </em>
                                <span>English</span>
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={() => handleLanguageChange(LANGUAGE.INDONESIAN)}
                              >
                                <em className="me-2">
                                  <img src={IMAGES.indonesiaflag} alt="" width="30" />
                                </em>
                                <span>Indonesian</span>
                              </a>
                            </div>
                        </div>
                          </CheckClickOutside>
                      </div> */}
                    </div>
                  ) : (
                    <>

                    </>
                  )}
                </div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${active == 1 ? "active" : ""}`}
                      aria-current="page"
                      onClick={() => location.pathname == ROUTES.HOME ? "" : changeRoute(ROUTES.HOME, 1)}
                    >
                      {t("home")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${active == 2 ? "active" : ""}`}
                      onClick={() => changeRoute(ROUTES.PROMOTION, 2)}
                    >
                      {t("promotions")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${active == 3 ? "active" : ""}`}
                      onClick={() => changeRoute(ROUTES.GAMES, 3)}
                    >
                      {t("games")}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${active == 4 ? "active" : ""}`}
                      onClick={() => changeRoute(ROUTES.HOWITWORKS, 4)}
                    >
                      {t("howitworks")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="right_header d-flex align-items-center">
              {/* <CheckClickOutside onClick={() => setLangDD(false)}>
              <div className="dropdown custom_dropdown user_Lang me-3 d-none d-lg-inline-block">
                <button className="dropbtn" onClick={() => setLangDD(!langDD)}>
                  <img
                    src={language == "in" ? IMAGES.indonesiaflag : IMAGES.usflg}
                    width="30"
                    alt=""
                  />
                </button>
                  <div className={`dropdown-menu dropdown-menu-end ${langDD ? "show" : ""}`}>
                    <a
                      className="dropdown-item"
                      onClick={() => handleLanguageChange(LANGUAGE.ENGLISH)}
                    >
                      <em className="me-2">
                        <img src={IMAGES.usflg} alt="" width="30" />
                      </em>
                      <span>English</span>
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => handleLanguageChange(LANGUAGE.INDONESIAN)}
                    >
                      <em className="me-2">
                        <img src={IMAGES.indonesiaflag} alt="" width="30" />
                      </em>
                      <span>Indonesian</span>
                    </a>
                  </div>
              </div>
                </CheckClickOutside> */}

              {token ? (
                <>
                  <div className="dollar_box_item d-md-flex justify-content-between ms-3 me-2">
                    <span className="me-2">
                      {userDetails?.credit ? parseInt(userDetails?.credit).toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(",") : 0}
                    </span>
                    <button
                      className="btn"
                      type="submit"
                      onClick={handleCreditRefresh}
                    >
                      <img src={IMAGES.arrows_rotate} width="30" alt="plus" />
                    </button>
                  </div>

                  <div className="dropdown custom_dropdown">
                    <button
                      className="btn dropdown-toggle"
                      onClick={() => setShowDD(!showDD)}
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {userDetails?.userName}
                    </button>
                    <CheckClickOutside onClick={() => setShowDD(false)}>
                      <div
                        className={`dropdown-menu dropdown-menu-end ${showDD ? "show" : ""}`}
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.DEPOSITS);
                            setShowDD(false);
                          }}
                        >
                          {t("deposit")}
                        </a>

                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push({
                              pathname: ROUTES.DEPOSITS,
                              type: NAVIGATION_BAR.WITHDRAWAL,
                            });

                            setShowDD(false);
                          }}
                        >
                          {t("withdraw")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.CHANGE_PASSWORD);
                            setShowDD(false);
                          }}
                        >
                          {t("change-password")}
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            history.push(ROUTES.REFERRAL);
                            setShowDD(false);
                          }}
                        >
                          {t("referral-link")}
                        </a>
                        <a className="dropdown-item" onClick={logoutHandler}>
                          {t("logout")}
                        </a>
                      </div>
                    </CheckClickOutside>
                  </div>
                </>
              ) : (
                <>
                  <div className="head_btn_group d-flex ms-3">
                    <Button
                      className="btn btn-md btn-primary me-md-3 me-2"
                      type="submit"
                      onClick={() => {
                        setOpenLoginModal(true);
                      }}
                    >
                      {t("login")}
                    </Button>
                    <Button
                      className="btn btn-md btn-red"
                      onClick={() => {
                        history.push(ROUTES.REGISTER);
                      }}
                    >
                      {t("register")}
                    </Button>
                  </div>
                </>
              )}

              <button className="navbar-toggler ps-md-3" type="button">
                <span
                  className="navbar-toggler-icon"
                  onClick={() => setExpandNav(!expandNav)}
                ></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
      
       <div className="running-text">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-12">
                   <span className="marquee-label text-uppercase">{t("latest-info")} :</span>
              </div>
              <div className="col-md-10 col-sm-12">
                <div className="marquee-wrapper">
                  <div className="marquee">
                    <marquee> Welcome to BOLANTANGKAS 2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</marquee>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>

      {children}

      <LoginModal
        open={openLoginModal}
        setOpen={setOpenLoginModal}
        toggleForgotModal={() => setOpenForgotModal(!openForgotModal)}
      />
      <ForgotModal
        open={openForgotModal}
        setOpen={setOpenForgotModal}
        toggleLoginModal={() => setOpenLoginModal(!openLoginModal)}
      />
      <WelcomeModal open={openWelcome} setOpen={setOpenWelcome} />
    </>
  );
};

export default AppLayout;
