import Select from "react-select";
import "./style.scss";

const Dropdown = ({
  defaultValue = { label: "Select", value: "" },
  isSearchable = false,
  isClearable = false,
  options = [],
  placeholder = "",
  isDisabled = false,
  value,
  onChange = () => {},
  className = "",
  isFormik = false,
  ...props
}) => {

  return (
    <>
      <Select
        name={props?.name}
        // defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        isSearchable={isSearchable}
        // className={className}
        isClearable={isClearable}
        placeholder={placeholder}
        className="react-select-container"
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={value}
        // menuIsOpen={true}
      />
      {/* {isFormik ?
      <div className="error">
        <ErrorMessage name={field?.name} />
      </div> : ""} */}
    </>
  );
};

export default Dropdown;

